import {useRef, useCallback, useState, useEffect, useReducer} from 'react';
import {emit, subscribe} from '../../Emitter';

const ProgressBar = () => {
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const formatTime = (time) => {
      if (time && !isNaN(time)) {
        const minutes = Math.floor(time / 60);
        const formatMinutes =
          minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.floor(time % 60);
        const formatSeconds =
          seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${formatMinutes}:${formatSeconds}`;
      }
      return '00:00';
    };
  
    useEffect(() => {
      subscribe('update_play_time', (ev) => {
        setCurrentTime(ev.detail.time);
        setDuration(ev.detail.duration);
      }, "JukeboxPlayer.ProgressBar");
    }, []);

    return (
      <div className="progress">
        <div className='progress-time'>
            <span className="time current">{formatTime(currentTime)}</span>
        </div>
        <div className='progress-bar-container'>
            <input
            type="range"
            value={currentTime}
            min={0}
            max={duration}
            className='progress-bar'
            readOnly
            />
        </div>
        <div className='progress-time'>
            <span className="time">{formatTime(duration)}</span>
        </div>
      </div>
    );
};
  
export default ProgressBar;

