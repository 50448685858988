import { useEffect, useRef } from "react";
import {emit, subscribe} from '../../Emitter';

const AudioPlayer = () => {
  const intervalRef = useRef();
  const audioRef = useRef(new Audio());

    const onEnded = () => {
        emit('on_song_end', {}, "JukeboxPlayer.DisplayTrack");
    };
    
    const handlePlayNow = (ev) => {
      console.log("Updating player", ev.detail)
      audioRef.current.pause();
      audioRef.current.src = ev.detail.url;
      audioRef.current.load();
      audioRef.current.play();
    }

    const handlePlay = () => {
      audioRef.current.play();
    };

    const handlePause = () => {
      audioRef.current.pause();
    };

    const handleRestartSong = () => {
      audioRef.current.currentTime = 0
    };

    const handleSetVolume = (ev) => {
      audioRef.current.volume = ev.detail.level / 100;
    };

    useEffect(() => {
      subscribe('play_now', handlePlayNow, "JukeboxPlayer.AudioPlayer");
      subscribe('on_play', handlePlay, "JukeboxPlayer.AudioPlayer");
      subscribe('on_pause', handlePause, "JukeboxPlayer.AudioPlayer");
      subscribe('on_restart_song', handleRestartSong, "JukeboxPlayer.AudioPlayer");
      subscribe('on_set_volume', handleSetVolume, "JukeboxPlayer.AudioPlayer");
    }, []);
    
    useEffect(() => {
      if(intervalRef.current) {
          clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
          if(audioRef.current && !isNaN(audioRef.current.duration)) {
              emit("update_play_time", {
                  time: audioRef.current.currentTime,
                  duration: audioRef.current.duration,
              }, "JukeboxPlayer.ProgressBar")
          }
      }, 1000);
  }, [audioRef]);

    return (<>
      <audio ref={audioRef} onEnded={onEnded}/>
    </>
    );
};
export default AudioPlayer;
  