import React, { useState, useRef } from 'react';
import AudioPlayer from './AudioPlayer';
import PlayerStatus from './PlayerStatus';
import NowPlaying from './NowPlaying';
import ProgressBar from './ProgressBar';
import {emit, subscribe} from '../../Emitter';

export default () => {
    return (
      <div className="jubebox-widget widget">
        <div className="inner">
          <AudioPlayer />
          <PlayerStatus />
          <ProgressBar />
          <NowPlaying />
        </div>
      </div>
    );
  };
  