let globalListeners = [];

export const subscribeAll = (listener, label) => {
    console.debug(`Subscribing all events from ${label}}`)
    globalListeners.push(listener);
}

export const subscribe = (eventName, listener, label) => {
    console.debug(`Subscribing ${eventName} from ${label}}`)
    document.addEventListener(eventName, listener);
}

export const dispatchEvent = (eventName, data, label) => {
    console.debug(`Emitting ${eventName} from ${label}`, data)
    const event = new CustomEvent(eventName, {detail: data});
    document.dispatchEvent(event);
}

export const emit = (eventName, data, label) => {
    dispatchEvent(eventName, data, label)
    const event = new CustomEvent(eventName, {detail: data});
    globalListeners.forEach(listener => {
        listener(event);
    })
}
