import React, { useState, useEffect } from "react";
import {
    IoPlaySharp,
    IoPauseSharp,
} from 'react-icons/io5';
import {
    IoMdVolumeHigh,
    IoMdVolumeOff,
    IoMdVolumeLow,
} from 'react-icons/io';
import { subscribe} from '../../Emitter';


const PlayStatus = () => {
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
      subscribe("on_play", () => setIsPlaying(true), "JukeboxPlayer.PlayerStatus");
      subscribe("on_pause", () => setIsPlaying(false), "JukeboxPlayer.PlayerStatus");
    }, []);

    return (
        <div className="play-status">
            {isPlaying ? <IoPlaySharp /> : <IoPauseSharp />}
        </div>
    )
}

const VolumeStatus = () => {
  const [volume, setVolume] = useState(60);
  const [muteVolume, setMuteVolume] = useState(false);

  const handleSetVolume = (ev) => {
    setVolume(ev.detail.level);
  };

  const handleMute = (ev) => {
    setMuteVolume((prev) => !prev);
  };

  useEffect(() => {
    subscribe("on_set_volume", handleSetVolume, "JukeboxPlayer.Controls");
    subscribe("on_mute_volume", handleMute, "JukeboxPlayer.Controls");
  }, []);

  return (
    <div className="volume-status">
        {muteVolume || volume < 5 ? (
          <IoMdVolumeOff />
        ) : volume < 40 ? (
          <IoMdVolumeLow />
        ) : (
          <IoMdVolumeHigh />
        )}
    </div>
  )
}

const PlayerStatus = () => {
  return (
    <div className="playback-status">
      <PlayStatus />
      <VolumeStatus />
    </div>
  );
};
export default PlayerStatus;