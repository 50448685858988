import {useEffect, useState, useRef} from 'react';

const DateTimeWidget = () => {
    const [dateTime, setDateTime] = useState(new Date());
    const intervalRef = useRef();
    useEffect(() => {
        if(intervalRef.current) clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            setDateTime(new Date());
        }, 1000);
    }, []);
    const formatDateTime = (dateTime) => {
        const parts = dateTime.toLocaleString().split(", ");
        const dt = parts[0];
        const [tm, am_pm] = parts[1].split(" ");
        const tm_parts = tm.split(":");
        return [dt, tm_parts[0] + ":" + tm_parts[1] + " " + am_pm];
    }
    const [date, time] = formatDateTime(dateTime);
    return (
        <div className="datetime-widget widget">
            <p className="datetime">{time}</p>
            <p className="datetime">{date}</p>
        </div>
    )
}
export default DateTimeWidget;