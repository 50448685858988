import {emit, subscribe, subscribeAll, dispatchEvent} from './../../Emitter';

const NAMESPACE = 'urn:x-cast:com.loki-console.text';

export const sendMessage = (context, message) => {
    context.sendCustomMessage(NAMESPACE, undefined, message);
}

export const registerMessageListener = (context) => {
    context.addCustomMessageListener(NAMESPACE, (customEvent) => {
        try {
            if(customEvent.type == "message") {
              const data = JSON.parse(customEvent.data.text);
              dispatchEvent(data.event, data.data, "cast")
            }
          } catch (e) {
            emit("error", {"error": e, "event": customEvent}, "cast")
          }
    });
}
