import { useEffect, useState } from 'react';
import {emit, subscribe, subscribeAll, dispatchEvent} from './../../Emitter';
import { sendMessage, registerMessageListener } from './message';

function Chromecast() {
  useEffect(() => {
    const context = window.cast.framework.CastReceiverContext.getInstance();
    subscribeAll((event) => {
        sendMessage(context, {
            event: event.type,
            data: event.detail
        })
    }, "cast");
    registerMessageListener(context);
  
    const options = new window.cast.framework.CastReceiverOptions();
    options.disableIdleTimeout = true;
    context.start(options);
    console.log("Chromecast receiver started")
  }, [])
  return (<></>);
}

export default Chromecast;
