import { useState, useEffect } from 'react';
import {emit, subscribe} from '../../Emitter';

export default () => {
    const [currentTrack, setCurrentTrack] = useState("");

    useEffect(() => {
        subscribe("play_now", (ev) => {
            setCurrentTrack(ev.detail.display);
        }, "JukeboxPlayer.NowPlaying");
    }, []);

    return (
        <div>
            <div className="marquee"><p>{currentTrack}</p></div>
        </div>
    )
}