import './App.css';
import JukeboxPlayer from './widgets/JukeboxPlayer/widget';
import Chromecast from './widgets/Chromecast/widget';
import DateTimeWidget from './widgets/DateTime/widget';

import { useEffect } from 'react';
import {emit, subscribe} from './Emitter';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="widget-dashboard">
          <DateTimeWidget />
          <div className="weather-widget widget">Weather</div>
          <div className="bus-widget widget">Bus Schedule</div>
          <div className="slideshow widget">Slideshow</div>
          <div className="calendar-widget widget">Calendar</div>
          <div className="discord-widget widget">Discord</div>
          <JukeboxPlayer />
          <Chromecast />
        </div>
      </header>
    </div>
  );
}

export default App;
